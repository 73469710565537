import React from "react";
import SectionHead from "../../../../components/SectionHead";
import LoadingDialogue from "../../../../components/LoadingDialogue";
import Document from "../../../../components/Document";
import EmptyDialogue from "../../../../components/EmptyDialogue";
import { extractFileNameFromPath } from "../../../../utility";
import StaticLink from "../../../../components/StaticLink";

export default function AdditionalResourcesView({
  files,
  sectionRef,
  initializing,
  links,
}) {
  return (
    <div className="section__container">
      <div className="section__anchor__main" ref={sectionRef}></div>
      <SectionHead title={"Additional Resources"} method={null} />
      <div
        className="section__body clamp__height"
        style={{ position: "relative" }}
      >
        {initializing ? (
          <div className="empty__content">
            <LoadingDialogue />
          </div>
        ) : (
          <div className="document__container col2">
            <StaticLink
              title="LIVE Feature Log"
              url={
                "https://docs.google.com/spreadsheets/d/1TqHeYKcOiZAsOhR34x1bqPijHxb0BEqy/edit?usp=sharing&ouid=100150954271667870008&rtpof=true&sd=true"
              }
            />
            {((files && Array.isArray(files) && files.length > 0) ||
              (links && Array.isArray(links) && links.length > 0)) && (
              <>
                {Array.isArray(links) &&
                  links.map((link, index) => (
                    <StaticLink key={index} title={link.label} url={link.url} />
                  ))}
                {Array.isArray(files) &&
                  files.map((file, index) => (
                    <Document
                      key={index}
                      name={
                        file.label
                          ? file.label
                          : extractFileNameFromPath(file.file_name)
                      }
                      path={{
                        prepend: null,
                        path: file.fileURI,
                      }}
                      externalURL={file.s3URI}
                      lastUpdated={"2d Ago"}
                    />
                  ))}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
