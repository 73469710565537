import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Icon, Loader } from "semantic-ui-react";
import { ToolbarOptions } from "../../../../data";
import Tooltip from "../../../../components/Tooltip";
import ErrorBoundary from "../../../../components/ErrorBoundary";
import RichTextEditorError from "../../../../components/RichTextEditorError";

export default function DailySchedule({
  inputs,
  submitDailySchedule,
  initializing,
  sectionRef,
}) {
  return (
    <form className="form" onSubmit={(e) => e.preventDefault()}>
      <div className="section__anchor" ref={sectionRef}></div>
      <div className="form__section daily__schedule">
        <div className="form__section__col">
          <label style={{ textTransform: "uppercase" }}>
            On Site
            <br />
            Daily Schedule
          </label>
          <button className="save__btn" onClick={() => submitDailySchedule()}>
            <Icon name="save" />
            <span>Save Updates</span>
          </button>

          {initializing && (
            <span
              className="initializing__loader"
              style={{ marginTop: 20, display: "block" }}
            >
              <Loader active inline size="mini" /> &nbsp; Loading
            </span>
          )}
        </div>
        <div className="form__section__col full">
          {inputs.map((input, index) => (
            <div className="section__input" key={index}>
              <label className="input__label">
                {input.label}
                {input.required && <span className="req">*</span>}
                <br />
                <Tooltip
                  label={"Text only"}
                  tip={
                    "Please do not insert or paste images, files or anything other than rich-text."
                  }
                />
              </label>
              <ErrorBoundary
                fallback={
                  <RichTextEditorError
                    handleReset={() => submitDailySchedule(" ")}
                  />
                }
              >
                <Editor
                  toolbar={ToolbarOptions}
                  editorState={
                    input.value.raw === ""
                      ? EditorState.createEmpty()
                      : input.value.draft
                  }
                  onEditorStateChange={(editorState) => {
                    const obj = {
                      raw: draftToHtml(
                        convertToRaw(editorState.getCurrentContent())
                      ),
                      draft: editorState,
                    };
                    input.setter(obj);
                  }}
                />
              </ErrorBoundary>
            </div>
          ))}
        </div>
      </div>
    </form>
  );
}
