import React, { useState, useEffect } from "react";
import PersonnelView from "./view";
import {
  postPersonnel,
  deletePersonnel,
} from "../../../../api/Resources/personnel";
import { evaluateTokenExpiry } from "../../../../api";
import { v4 as uuid } from "uuid";
import { useNotification } from "../../../../context/NotificationProvider";
import { useAuth } from "../../../../context/AuthProvider";

const emptyRow = {
  personnel_position: "",
  personnel_name: "",
  email_address: "",
  phone_number: "",
  id: "",
  tournament_id: "",
};

const positions = [
  "Technical Manager (STUDIOS)",
  "LIVE Production Manager (STUDIOS)",
  "WF Production Manager (STUDIOS)",
  "ON SITE Technical Manager",
  "ON SITE Production Manager",
  "Other",
];

export default function PersonnelController({
  tournamentId,
  personnel,
  sectionRef,
}) {
  const [isInitializing, setIsInitializing] = useState(false);
  const notificationContext = useNotification();
  const { setNotification, setDisplayNotification } = notificationContext;
  const authContext = useAuth();
  const { token, logout } = authContext;
  const [rows, setRows] = useState([]);
  const [loadingRows, setLoadingRows] = useState([]);

  const bulkPost = async () => {
    const tokenExpired = evaluateTokenExpiry();
    if (tokenExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }

    let data = [...rows];
    const { idToken } = token;

    for (let i = 0; i < data.length; i++) {
      if (!data[i].id) continue;

      try {
        setLoadingRows((loadingRows) => {
          return loadingRows.includes(data[i].id)
            ? loadingRows
            : [...loadingRows, data[i].id];
        });
        let result = await postPersonnel({
          ...data[i],
          token: idToken.value,
        });
        if (result.error) throw new Error("Error adding personnel");
      } catch (error) {
        setNotification({
          message: "Error saving personnel",
          iconName: "ban",
          hex: "var(--danger-1)",
        });
        return setDisplayNotification(true);
      }

      setLoadingRows((loadingRows) =>
        loadingRows.filter((id) => id !== data[i].id)
      );
    }
  };

  const addRow = async () => {
    const tokenExpired = evaluateTokenExpiry();
    if (tokenExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }

    let newPersonnel = {
      ...emptyRow,
      tournament_id: tournamentId,
      id: uuid(),
    };
    setIsInitializing(true);
    try {
      const { idToken } = token;
      let result = await postPersonnel({
        ...newPersonnel,
        token: idToken.value,
      });
      if (result.error) throw new Error("Error adding personnel");
      setRows((rows) => [...rows, newPersonnel]);
    } catch (error) {
      setNotification({
        message: "Error adding personnel",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }

    setIsInitializing(false);
  };

  const deleteRow = async (rowId) => {
    const tokenExpired = evaluateTokenExpiry();
    if (tokenExpired) {
      logout();
      setNotification({
        message: "Session expired. Please login.",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }

    setLoadingRows((loadingRows) => {
      return loadingRows.includes(rowId)
        ? loadingRows
        : [...loadingRows, rowId];
    });

    try {
      const { idToken } = token;

      let result = await deletePersonnel({ id: rowId, token: idToken.value });
      if (result.error) throw new Error("Error deleting personnel");

      let data = [...rows];

      setRows(data.filter((r) => r.id !== rowId));
    } catch (error) {
      setNotification({
        message: "Error deleting personnel",
        iconName: "ban",
        hex: "var(--danger-1)",
      });
      return setDisplayNotification(true);
    }

    setLoadingRows((loadingRows) => loadingRows.filter((id) => id !== rowId));
  };

  const handleOnChange = ({ rowId, key, value }) => {
    let data = [...rows];
    try {
      let foundRecord = data.filter((d) => d.id === rowId)[0];
      foundRecord[key] = value;
      setRows(data);
    } catch (error) {
      console.log(error);
    }
  };

  const hydratePersonnel = () => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      let formattedPersonnel = personnel.map((p) => ({
        personnel_position: p.personnel_position,
        personnel_name: p.personnel_name,
        email_address: p.email_address,
        phone_number: p.phone_number,
        id: p.id,
        tournament_id: p.tournament_id,
      }));

      setRows(formattedPersonnel);
    }
  };

  useEffect(() => {
    hydratePersonnel();
  }, [personnel]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PersonnelView
      initializing={isInitializing}
      sectionRef={sectionRef}
      emptyRow={emptyRow}
      addRow={addRow}
      deleteRow={deleteRow}
      rows={rows}
      positions={positions}
      handleOnChange={handleOnChange}
      bulkPost={bulkPost}
      loadingRows={loadingRows}
    />
  );
}
